@import "colors";
footer {
  background: $color2;
  color: $color4;
  width: 100%;
  h2{
    margin-bottom: 0.6rem;
  }
}
#contact {
  padding: 1rem 0;
}
footer p {
  background: $color3;
  padding: 0.7rem;
  text-align: center;
}

.footer-contact svg{
  margin-right: 0.5rem;
}

#contact a, #contact span{
  color: $color4;
  display: block;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.4rem 0;
}

#contact a:hover {
  color: $color3;
}

@media screen and (min-width: 768px) {
  #contact h2 {
    font-size: 1.7rem;
  }
}
