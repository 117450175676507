@import "colors";

#main-post {
  margin-top: 1.5rem;
}

.post-loader {
  max-width: 48rem;
  margin: 0 auto 1rem;
  box-shadow: 0px 0px 0.6rem $color2;
}

.single-post {
  text-align: center;
  max-width: 48rem;
  margin: 0 auto;
  box-shadow: 0px 0px 0.6rem $color2;
  padding: 0 0 1rem 0;
  header {
    background-color: $color1;
    color: $color4;
  }
  img,
  video {
    max-width: 100%;
  }
  h1 {
    padding: 0.7rem 0;
  }
  h2 {
    padding: 1rem 0;
  }
  h3 {
    text-align: left;
    padding: 1rem;
  }
  div {
    padding-bottom: 1rem;
  }
  p {
    text-align: left;
    padding: 0 1rem 1rem;
  }
  ul,
  ol {
    text-align: left;
    padding: revert;
    margin-bottom: revert;
  }
  a {
    color: $color3;
  }
}
.wp-block-table{
  overflow-x: auto;
  table{
    margin: 0 auto;
    border-collapse: collapse;
  }
  th, td{
    border: 1px solid $color3;
    padding: 0.4rem;
  }
  figcaption{
    font-size: .8rem;
  }
}

.share-post {
  margin-top: 1rem;
  text-align: center;
}

.share-icon {
  display: inline-block;
  margin: 0 0 1rem;
  color: $color2;
  font-size: 3rem;
  &:hover,
  &:active {
    color: $color3;
  }
}

.share-icon:nth-of-type(2) {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  .single-post h1 {
    font-size: 2.5rem;
  }
  hr {
    margin: 2rem 0;
  }
}
