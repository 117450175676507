@import "colors";
#main-posts {
  margin-top: 2rem;
  text-align: center;
}
.post {
  box-shadow: 0px 3px 6px $color1;
  color: $color4;
  background-color: $color1;
  max-width: 32.001rem;
  margin: 1rem auto;
}
.post > div {
  padding: 1.5rem;
}
.post div > div {
  margin: 0.26rem 0 1.5rem;
}
.post img {
  width: 100%;
}
.post p {
  text-align: left;
  margin-bottom: 0.8rem;
}
.category-link {
  color: $color3;
}
.post-link {
  color: $color4;
  text-decoration: none;
  background: $color3;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  transition: all 0.2s ease;
  &:hover,
  &:active {
    background: $color2;
  }
}

@media screen and (min-width: 768px) {
  #posts-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin: 2rem 0;
  }
}
