@import "colors";
@import "NavBar";
#gradient-layer {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, $color1, $color3);
  background-repeat: no-repeat;
  background-size: cover;
  color: $color4;
  text-align: center;
}
#gradient-layer > .wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.portfolio-link {
  text-decoration: none;
  background: none;
  padding: 0.4rem 0.5rem;
  color: $color4;
  border-radius: 20px;
  border: 1px solid $color4;
  font-size: 1.2rem;
}
#gradient-layer img {
  width: 30%;
  transform: translateY(25%);
}
#landing-text h1 {
  font-size: 2.3rem;
}
#landing-text h2 {
  font-size: 1.1rem;
  font-weight: normal;
}
.non-static-text {
  border-right: 0.1rem solid $color4;
}
.social-icons {
  display: flex;
  width: 55%;
  justify-content: space-between;
}
.social-icons a {
  color: $color4;
  font-size: 1.8rem;
  &:hover {
    color: lighten($color1, 10);
  }
  &:last-child {
    cursor: pointer;
  }
}
@media screen and (min-width: 768px) {
  .portfolio-link {
    display: none;
  }
  #gradient-layer img {
    width: 18%;
  }
  #landing-text {
    h1 {
      font-size: 2.9rem;
    }
    h2 {
      font-size: 1.4rem;
    }
  }
  .social-icons {
    width: 32%;
    transform: translateY(-25%);
  }
  .social-icons a {
    font-size: 2.4rem;
  }
}
