@import "colors";
@import "NavBar";
#about {
  background: $color1;
  color: $color4;
}
#about article {
  font-size: 1.1rem;
}
#about section {
  margin: 3rem 0;
  a {
    color: $color3;
  }
  img {
    display: block;
    margin: 0 auto 1.5rem;
  }
}
#about h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1.8rem;
}
#about h2,
#about h3 {
  margin-bottom: 1rem;
}
#social {
  display: flex;
  justify-content: space-around;
}

@media screen and (min-width: 768px) {
  #about article {
    padding: 2rem 0;
  }
  #about section {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: center;
    margin: 3rem 0;
    img {
      margin: 0 auto;
    }
  }
}
